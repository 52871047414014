<template>
  <div class="background-uploader">
    <div v-if="$refs.upload && $refs.upload.dropActive && fileDrop" class="drop-active">
      Положите файл сюда
    </div>
    <div class="files-background">
      <file-upload
        class="file-uploader"
        post-action="/upload/post"
        :multiple="multiple"
        :drop="drop"
        :drop-directory="dropDirectory"
        v-model="deep.files"
        ref="upload"
        v-if="showOnlyFiles"
      >
        <i class="icon-paper-clip"></i>
      </file-upload>
      <div
        class="files-info"
        v-for="(file, index) in deep.files"
        :key="index"
        @click="deep.files.splice(index, 1)"
      >
        <div class="files-info-img">
          <IcoMoonWrap
            v-if="file.type === 'image/png'"
            :name="'picture'"
            :font-size="18"
            :pathCount="3"
          />
          <IcoMoonWrap
            v-if="file.name.split('.')[1] === 'docx'"
            :name="'doc'"
            :font-size="18"
            :pathCount="7"
          />
          <IcoMoonWrap
            v-if="file.name.split('.')[1] === 'xlsx'"
            :name="'xls'"
            :font-size="18"
            :pathCount="7"
          />
        </div>
        <div class="files-info-name" v-b-tooltip.top="file.name">
          {{ cutterText(file.name) }}
        </div>
        <div class="files-info-size">{{ sizeFloor(file.size) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import IcoMoonWrap from "@/assets/aop-icons/IcoMoonWrap";
const FileUpload = () => import("vue-upload-component");

export default {
  name: "FileUploader",
  mixins: [],
  props: ["multiple", "drop", "dropDirectory", "fileDrop", "deep", "lengthText", "showOnlyFiles"],
  components: { IcoMoonWrap, FileUpload },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    sizeFloor(size) {
      let tb = 1099511627776;
      let gb = 1073741824;
      let mb = 1048576;
      let kb = 1024;
      if (size >= tb) {
        return Math.floor(size / tb) + "tb";
      } else if (size >= gb && size < tb) {
        return Math.floor(size / gb) + "gb";
      } else if (size >= mb && size < gb) {
        return Math.floor(size / mb) + "mb";
      } else if (size >= kb && size < mb) {
        return Math.floor(size / kb) + "kb";
      } else {
        return size + "b";
      }
    },
    cutterText(text) {
      return text.substr(0, this.lengthText) + "...";
    }
  },
  computed: {},
  watch: {
    deep: {
      deep: true,
      handler(val) {}
    }
  },
  validations: {},
  directives: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
.background-uploader {
  width: 100%;
  min-height: 58px;
  position: relative;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 10px;
  .drop-active {
    width: 98%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: 2px dashed #4b83f0;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #4b83f0;
    z-index: 100;
    background-color: white;
  }
  .files-background {
    position: relative;
    z-index: 99;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 48px);
    grid-row-gap: 12px;

    .icon-paper-clip {
      color: $color-text-secondary;
      font-size: 20px;
      margin-right: 10px;
    }
    .file-uploader {
      /deep/ {
        label {
          cursor: pointer;
        }
      }
    }
    .files-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-name {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 11px;
        color: #35455e;
        margin-top: 3px;
      }
      &-size {
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 7px;
        color: #91a0ba;
        margin-top: 2px;
      }
    }
  }
}
</style>
